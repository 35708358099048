import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logoDark from '../../assets/images/logo-dark.png'

const OffCanvasMenu = (props) => {
    const [iconToggle1, setIconToggle1] = useState(true)
    const [iconToggle2, setIconToggle2] = useState(true)
    const [iconToggle3, setIconToggle3] = useState(true)

    const { parentMenu } = props;
    const location = useLocation();

    return (
        <>
            <div className='offcanvas-area'>
                <Link data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="ri-menu-2-line"></i></Link>
                <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                    <div className="offcanvas-header">
                        <Link to="/#"><img src="https://foorweb.net/Logo.png" alt="DarkLogo" /></Link>
                        <button type="button" className="offcanvasClose" data-bs-dismiss="offcanvas" aria-label="Close"><i className="ri-close-fill"></i></button>
                    </div>
                    <div className="offcanvas-body">
                        <nav className="navbar navbar-light">
                            <div className="w-100">
                                <div className="collapse navbar-collapse show" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item dropdown">
                                            <Link className={parentMenu === 'pages' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setIconToggle1(!iconToggle1)}>
                                                الرئيسية
                                                {iconToggle1 ? <i className="ri-add-line"></i> : <i className="ri-subtract-line"></i>}
                                            </Link>

                                        </li>
                                        {/* <li className="nav-item">
                                            <Link className={location.pathname === '/about' ? 'nav-link active' : 'nav-link'} to="/about#">About Us</Link>
    </li>*/}
                                        <li className="nav-item dropdown">
                                            <Link className={parentMenu === 'pages' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} onClick={() => { window.open("/Pricing") }}>
                                                الباقات
                                                {iconToggle2 ? <i className="ri-add-line"></i> : <i className="ri-subtract-line"></i>}
                                            </Link>

                                        </li>
                                        <li className="nav-item dropdown">
                                            <Link className={parentMenu === 'pages' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} to="/privacy" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { window.open("/privacy") }}>
                                                سياسية الخصوصية
                                                {iconToggle1 ? <i className="ri-add-line"></i> : <i className="ri-subtract-line"></i>}
                                            </Link>

                                        </li>
                                        <li className="nav-item dropdown ">
                                            <Link className={parentMenu === 'blog' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} onClick={() => { window.open("https://t.me/+PA1piqOcr2AyMjY0") }}>
                                                ملتقى الخبراء
                                                {iconToggle3 ? <i className="ri-add-line"></i> : <i className="ri-subtract-line"></i>}
                                            </Link>

                                        </li>
                                        {/*  <li className="nav-item">
                                            <Link className={location.pathname === '/contact' ? 'nav-link active' : 'nav-link'} to="/contact#">Contact</Link>
</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </nav>
                        <div className="offcanvas-icon-list">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/F0orweb"><i className="ri-facebook-fill"></i></a>
                                </li>
                                {/* <li>
                                    <a href="https://www.twitter.com/"><i className="ri-twitter-fill"></i></a>
                                </li>*/}
                                <li>
                                    <a href="https://www.youtube.com/@foorweb6051"><i className="ri-video-fill"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/F0orweb"><i className="ri-instagram-fill"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OffCanvasMenu;