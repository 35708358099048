import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import imageIcon from '../../../assets/images/icons/image-icon.png'
import imageIcon1 from '../../../assets/images/icons/image-icon1.png'
import imageIcon2 from '../../../assets/images/icons/image-icon2.png'
import imageIcon3 from '../../../assets/images/icons/image-icon3.png'
import sectionBg4 from '../../../assets/images/banner/section-bg4.png'
import sectionBg5 from '../../../assets/images/banner/section-bg5.png'

const Home4Services = () => {
    return (
        <>
            <div className="section-bg-shape1">
                <section className="sc-service-section-area8 p-z-idex sc-pt-130 sc-md-pt-70 sc-pb-140 sc-md-pb-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <div className="sc-heading-area text-center sc-mb-55 sc-md-mb-40">
                                    <h3 className="heading-title">ندعمك ونساندك في تجارتك خطوة
                                        <span className="gradiant-color"> بخطوة</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-md-6">
                                <Fade bottom delay={100}>
                                    <div className="sc-service-content-box p-z-idex text-center sc-md-mb-30">
                                        <div className="service-icon">
                                            <img src="https://foorweb.net/react/store-41.png" alt="icons" />
                                        </div>
                                        <h4 className="sc-mb-0"><Link to="#">1 اعداد المتجر </Link></h4>
                                        <div className="description sc-mb-25">إنشاء متجرك الخاص خلال دقائق بأقل التكاليف.</div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <Fade bottom delay={200}>
                                    <div className="sc-service-content-box p-z-idex text-center sc-md-mb-30">
                                        <div className="service-icon">
                                            <img src="https://foorweb.net/react/editproduct.png" alt="Images" /> 
                                        </div>
                                        <h4 className="sc-mb-0"><Link to="#">2 إدراج المنتجات</Link></h4>
                                        <div className="description sc-mb-25"> ستتمكن من إدارة منتجاتك بكل سهولة تامة .</div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <Fade bottom delay={300}>
                                    <div className="sc-service-content-box p-z-idex text-center sc-md-mb-30">
                                        <div className="service-icon">
                                            <img src="https://foorweb.net/react/tasmim.png" alt="Images" />
                                        </div>
                                        <h4 className="sc-mb-0"><Link to="#">3 تصميم احترافي</Link></h4>
                                        <div className="description sc-mb-25">تصميم المتجر ومضهره هو مايكون أول انطباع لدى العميل عن مدى احترافيتك.</div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <Fade bottom delay={400}>
                                    <div className="sc-service-content-box p-z-idex text-center sc-md-mb-30">
                                        <div className="service-icon">
                                            <img src="https://foorweb.net/react/money41.png" alt="Images" />
                                        </div>
                                        <h4 className="sc-mb-0"><Link to="#">4  ابدأ البيع</Link></h4>
                                        <div className="description sc-mb-25">زيادة عدد الزيارات ومضاعفة نسبة المبيعات بدون عناء.</div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </section>
               
            </div>
        </>
    );
};

export default Home4Services;