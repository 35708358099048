import React from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { HashLink as Link } from 'react-router-hash-link';
import blogImg1 from '../../assets/images/blog/blog-img1.jpg'
import blogImg2 from '../../assets/images/blog/blog-img2.jpg'
import blogImg3 from '../../assets/images/blog/blog-img3.jpg'

const LatestBlog = () => {
    return (
        <>
            <section className="sc-blog-area sc-pt-125 sc-md-pt-65 sc-sm-mb-30">
                <div>
                </div>
            </section>
        </>
    );
};

export default LatestBlog;