import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const BreadCums = (props) => {
    const { pageTitle } = props;
    return (
        <>
            <div >
                
            </div>
        </>
    );
};

export default BreadCums;