import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import sectionBg4 from '../../../assets/images/banner/section-bg4.png'
import sectionBg5 from '../../../assets/images/banner/section-bg5.png'
import sectionBg6 from '../../../assets/images/banner/section-bg6.png'
import sectionBg7 from '../../../assets/images/banner/section-bg7.png'
import mediaImage from '../../../assets/images/feature/media-image.png'
import mediaImage1 from '../../../assets/images/feature/media-image1.png'

const Home4Media = () => {
    return (
        <>
            <section className="section-bg-shape1">
                <div className="sc-media-section-area p-z-idex sc-pb-140 sc-md-pb-80 sc-sm-pb-40">
                    <div className="container">
                    <div className="sc-heading-area sc-mb-40 sc-md-mb-30 text-center">
                            <h2 className="title sc-mb-25">ما يميز فوور <span className="gradiant-color">ويب</span></h2>
                            <div classname="shape wow " data-wow-delay="0.3s"></div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <Fade bottom>
                                    <div className="sc-media-item p-z-idex sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
                                        <div className="sc-heading-area sc-mb-20">
                                            <h3 className="banner-title sc-mb-0 p-z-idex">إدارة كل شيء بسهولة </h3>
                                        </div>
                                        <div className="description sc-mb-20">
                                        تستطيع من هاتفك متابعة طلبات المتجر و عملائك بكل سهولة و في أي وقت و بخطوات بسيطة
                                        </div>
                                      
                                        <div className="media-btn sc-mt-50">
                                            <Link className="text-new" onClick={() => { window.open( "https://admin.foorweb.store/login") }}>
                                                <span>اكتشف كيف تدير متجرك بسهولة</span>
                                            </Link>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 shake-y">
                                <Fade bottom delay={250}>
                                    <div className="sc-media-images shake-y sc-md-mt-75">
                                        <img src="https://foorweb.net/files2/feature_1.png" alt="Images" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc-media-section-two p-z-idex sc-pb-130 sc-md-pb-40 sc-sm-pb-10">
                    <div className="container">
                        <div className="row align-items-center flex-lg-row-reverse">
                        <div className="col-lg-6">
                                <Fade bottom>
                                    <div className="sc-media-item p-z-idex sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
                                        <div className="sc-heading-area sc-mb-20">
                                            <h3 className="banner-title sc-mb-0 p-z-idex">الدفع</h3>
                                        </div>
                                        <div className="description sc-mb-20">
                                        وفّر لعملائك خيارات متعددة للشراء من متجرك و فعّلها بكل سهولة
                                        </div>
                                        <div className="description sc-mb-20">
                                        ( بريدي موب , CIB , البطاقة الذهبية , فيزا , ماستركارد )
                                        </div>
                                        
                                      
                                        <div className="media-btn sc-mt-50">
                                            <Link className="text-new" onClick={() => { window.open( "https://admin.foorweb.store/login") }}>
                                                <span> اكتشف المزيد عن الدفع الإلكتروني   </span>
                                            </Link>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 shake-y ">
                                <Fade bottom delay={250}>
                                    <div className="sc-media-images shake-y p-z-idex sc-md-mt-30 sc-pr-30 sc-md-pr-0 sc-md-mb-50">
                                        <img src="https://foorweb.net/files2/feature_2.png" alt="Images" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <section className="section-bg-shape1">
                <div className="sc-media-section-area p-z-idex sc-pb-140 sc-md-pb-80 sc-sm-pb-40">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <Fade bottom>
                                    <div className="sc-media-item p-z-idex sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
                                        <div className="sc-heading-area sc-mb-20">
                                            <h3 className="banner-title sc-mb-0 p-z-idex">الشحن</h3>
                                        </div>
                                        <div className="description sc-mb-20">
                                        استخدم خيارات شحن متنوعة مع عملائك و بسعر مخفّض خاص بفوور ويب و اشحن بكل بساطة عبر (كل الشركات المتعاملة مع ZR express ,Maystro ,Ecotrack) والعديد من شركات الشحن في الجزائر
                                        </div>
                                      
                                        <div className="media-btn sc-mt-50">
                                            <Link className="text-new" onClick={() => { window.open( "https://admin.foorweb.store/login") }}>
                                                <span>  اكتشف الخيارات و المزايا الشحن  </span>
                                            </Link>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 shake-y">
                                <Fade bottom delay={250}>
                                    <div className="sc-media-images shake-y sc-md-mt-75">
                                        <img src="/files2/delivery.png" alt="Images" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sc-media-section-two p-z-idex sc-pb-130 sc-md-pb-40 sc-sm-pb-10">
                    <div className="container">
                        <div className="row align-items-center flex-lg-row-reverse">
                        <div className="col-lg-6">
                                <Fade bottom>
                                    <div className="sc-media-item p-z-idex sc-pr-110 sc-md-pr-0 sc-sm-pr-0">
                                        <div className="sc-heading-area sc-mb-20">
                                            <h3 className="banner-title sc-mb-0 p-z-idex">خدمات الشُركاء</h3>
                                        </div>
                                        <div className="description sc-mb-20">
                                        من خلال شُركائنا وفّرنا للتاجر نخبة من أفضل مقدمي الخدمات و بسعر خاص ( تصميم الجرافيك,
التصوير , ادوات التغليف , التسويق ... )
                                        </div>
                                       
                                        
                                      
                                        <div className="media-btn sc-mt-50">
                                            <Link className="text-new" onClick={() => { window.open( "https://admin.foorweb.store/login") }}>
                                                <span> تعرف على خدمات الشُركاء  </span>
                                            </Link>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="col-lg-6 shake-y">
                                <Fade bottom delay={250}>
                                    <div className="sc-media-images  p-z-idex sc-md-mt-30 sc-pr-30 sc-md-pr-0 sc-md-mb-50">
                                        <img src="https://foorweb.net/files2/feature_4.png" alt="Images" />
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        </>
    );
};

export default Home4Media;
