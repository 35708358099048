import React from 'react';
import Accordion from '../../Shared/Accordion';

const PriceFaq = () => {
    return (
        <>
            <div >
                
            </div>
        </>
    );
};

export default PriceFaq;