import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logo7 from '../../assets/images/logo7.png'

const Footer2 = (props) => {
    let { footerStyle, footerLogo } = props;
    return (
        <>
            <section className={`${footerStyle} sc-pt-80`}>
                <div className="container">
                    <div className="row padding-bottom">
                        <div className="col-lg-331 col-sm-6">
                            <div className="footer-about">
                                <div className="footer-logo sc-mb-25">
                                    <Link to="#"><img className="logo1" src="https://foorweb.net/Logo.png" alt="FooterLogo" /></Link>
                                </div>
                                <p className="footer-des">فوور ويب شركةجزائرية قابضة، تأسست في عام 2018 م، تهدف إلى تمكين قطاع التجارة الرقمية من الدخول إلى عالم التجزئة الإلكترونية بطريقة سهلة واحترافية.</p>
                                <ul className="about-icon">
                                    <li>
                                        <a href="https://www.facebook.com/F0orweb" target="_blank" rel="noreferrer"><i className="ri-facebook-fill"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/@foorweb6051" target="_blank" rel="noreferrer"><i className="ri-youtube-fill"></i></a>
                                    </li>
                                   
                                    <li>
                                        <a href="https://www.instagram.com/Foorweb" target="_blank" rel="noreferrer"><i className="ri-instagram-fill"></i></a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        
                        <div className="col-lg-33 col-sm-6 sc-sm-mt-40">
                           
                        </div>
                        <div className="col-lg-332 col-sm-6 sc-sm-mt-40">
                            <div className="footer-menu-area footer-menu-area-left sc-pl-65 sc-lg-pl-0 sc-md-pl-0">
                                <h4 className="footer-title white-color sc-md-mb-15">
ابقى على تواصل</h4>
                                <ul className="footer-menu-list">
                                    <li><i className="ri-mail-fill"></i><a href="Contact@foorweb.net">Contact@foorweb.net</a></li>
                                   
                                </ul>
                                <ul className="footer-mennu-list">
                                <li><i className="ri-phone-fill"></i><a href="tel:0557655863">0557655863</a></li>
                                </ul>
                                <ul className="footer-menuu-list">
                                <li className="footer-map"><i className="ri-map-pin-fill"></i> <span className="p-s"> المركز التجارى باب الدزاير - مركز البليدة </span></li>
                                </ul>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="border-top"></div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-text text-center">
                                    <p>&copy; {new Date().getFullYear()} <Link to="#"> Foorweb , </Link> جميع الحقوق محفوظة </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer2;