import React from 'react';
import aboutImg1 from '../../../assets/images/about/ab_img.png'
import aboutImg2 from '../../../assets/images/about/ab_img1.png'
import aboutImg3 from '../../../assets/images/about/ab_img2.png'

const AboutTeam = () => {
    return (
        <>
            <section>
                <div className="about-team-pages-area sc-pt-130 sc-md-pt-70 sc-pb-180 sc-md-pb-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1 p-z-idex">
                                <div className="sc-heading-area sc-mb-105 sc-md-mb-60 text-center">
                                    <div className="relative w-full max-w-lg mx-auto bg-white rounded-md shadow-lg">
                                        <div className="flex items-center justify-between p-4 border-b">
                                            <h4 className="text-lg font-medium text-gray-800">
                                                سياسة الخصوصية
                                            </h4>

                                        </div>
                                        <div className="space-y-2 p-4 mt-3 text-[15.5px] leading-relaxed text-gray-500">
                                            <div className="feature-container">

                                                <h3 className="positive-guidance-title">مقدمة
                                                </h3>
                                                <p>مرحبا بكم في فوورويب! من خلال التسجيل لإنشاء حساب فوورويب (على النحو المحدد في القسم 1) أو باستخدام أي من خدمات فوورويب (على النحو المحدد أدناه)، فإنك توافق على الالتزام بالشروط التالية (« شروط الإستخدام »).

                                                    كما هو مستخدم في شروط الاستخدام، تشير المصطلحات « نحن », و « فوورويب » إلى الطرف المتعاقد فوورويب ذي الصلة (كما هو محدد في القسم 13 أدناه)، بينما يشير مصطلح « أنت » إلى مستخدم فوورويب (لتسجيل أو استخدام خدمة فوورويب كفرد خاص) أو الشركة التي توظف مستخدم فوورويب (لتسجيل أو استخدام خدمة فوورويب كشركة) والشركات التابعة لها

                                                    تشمل الخدمات التي تقدمها فوورويب بموجب شروط الاستخدام العديد من المنتجات والخدمات التي تتيح لك إنشاء متجر خاص بك وبيع السلع والخدمات بسهولة للعملاء، سواء كانت المبيعات عبر الإنترنت (« الخدمات عبر الإنترنت »), أو المبيعات الشخصية (« خدمات نقاط البيع ») أو كليهما. جميع هذه الخدمات التي تقدمها فوورويب يشار إليها في شروط الاستخدام هذه باسم « الخدمة (الخدمات) ». تخضع كل أداة أو خاصية جديدة تضاف إلى الخدمات الحالية أيضًا لشروط الاستخدام. يمكنك الاطلاع على الإصدار الحالي من شروط الاستخدام في أي وقت على الرابط التالي : <li > الرابط: <a href={"https://admin.foorweb.store/sign-up"} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textEditor: 'underline' }} > https://admin.foorweb.store/sign-up</a></li>.

                                                    يجب أن تقرأ وتوافق على جميع الشروط والأحكام الواردة أو المشار إليها في شروط الاستخدام هذه، بما في ذلك سياسة الاستخدام المقبولة و سياسة خصوصية فوورويب قبل أن تتمكن من التسجيل لإنشاء حساب فوورويب أو استخدام خدمة فوورويب.  .</p>

                                                <h3 className="positive-guidance-title">شروط الحساب :</h3>

                                                <div className="d-flex justify-content-center   ">

                                                </div>
                                                <p>للوصول إلى الخدمات واستخدامها، يجب عليك إنشاء حساب فوورويب (« الحساب »). لإكمال عملية إنشاء الحساب، يجب عليك تقديم اسمك القانوني الكامل وعنوان عملك ورقم هاتفك وعنوان بريدك الإلكتروني و أي معلومات أخرى مطلوبة. قد ترفض فوورويب طلب الإنشاء الخاص بك أو تغلق حسابًا موجودًا لأي سبب، وفقًا لتقديرها.
                                                    لفتح حساب، يجب عليك: (1) أن تكون فوق سن 18، أو (2) أن تبلغ على الأقل سن الرشد في الولاية القضائية التي تقيم فيها وتستخدم الخدمات منها.
                                                    تؤكد أنك تتلقى أي خدمة تقدمها فوورويب فقط لغرض ممارسة نشاط مهني وليس لأغراض شخصية أو عائلية أو منزلية.
                                                    أنت تقر بأن فوورويب ستستخدم عنوان البريد الإلكتروني الذي قدمته عند فتح حساب، أو تم تعديله في أي وقت لتحديث وسائل الاتصال المفضلة لديك (« عنوان البريد الإلكتروني الأساسي »). يجب عليك التحقق من رسائل البريد الإلكتروني المرسلة إلى عنوان البريد الإلكتروني الرئيسي المقدم إلى فوورويب والتحقق من أن فوورويب قادر على إرسال واستقبال الرسائل. من الممكن فقط مصادقة اتصالات البريد الإلكتروني مع فوورويب عندما تأتي من عنوان البريد الإلكتروني الأساسي الخاص بك.
                                                    أنت مسؤول عن أمن كلمة السر الخاصة بك. لن يكون فوورويب مسؤولاً عن أي خسارة أو ضرر ناتج عن عدم قدرتك على الحفاظ على أمان حسابك وكلمة السر الخاصة بك.
                                                    يمكن لمستخدمي فوورويب فقط الاستفادة من الدعم التقني للخدمات. يجب توجيه أي أسئلة تتعلق بشروط الاستخدام لدعم التقني لفوورويب.
                                                    أنت توافق على عدم إعادة إنتاج أو نسخ أو بيع أو إعادة بيع أو استغلال أي جزء من الخدمة أو أي استخدام للخدمات أو أي وصول إلى الخدمات دون إذن مكتوب صريح من فوورويب.
                                                    أنت توافق على عدم الالتفاف على أي قيود تقنية للخدمات، وعدم استخدام أي أداة تسمح لك بالاستفادة من الوظائف أو الوظائف التي عادة ما تكون معطلة في الخدمات، أو تفكيك الخدمات أو عكس هندستها.
                                                    أنت توافق على عدم الوصول إلى الخدمات وعدم مراقبة أي محتوى أو معلومات مرتبطة بالخدمات باستخدام روبوت أو أداة استكشاف أو استرجاع أو أي وسيلة آلية أخرى.
                                                    أنت تفهم أنه يمكن نقل أجهزتك دون تشفير وتتضمن (أ) إرسال عبر شبكات متعددة ؛ (ب) التعديلات، بهدف ضمان امتثالها وتكييفها مع المتطلبات التقنية للاتصالات بالشبكات أو الأجهزة. المصطلح « معدات » يعني علاماتك ومحتوياتك المحمية بحقوق الطبع والنشر وجميع المنتجات والخدمات التي تبيعها من خلال الخدمات (بما في ذلك الأوصاف والأسعار)، بالإضافة إلى جميع الصور ومقاطع الفيديو والرسومات والمحتوى المكتوب والملفات الصوتية والرموز والمعلومات والبيانات الأخرى المقدمة إلى فوورويب أو الشركات التابعة لها من قبلك أو من قبل الشركات التابعة لك. </p>
                                                <h3 className="positive-guidance-title">ماذا يعني ذلك  :</h3>
                                                <p> أنت مسؤول عن حسابك والمواد التي تستوردها في خدمة فوورويب وتشغيل متجر فوورويب الخاص بك. في حالة انتهاك شروط استخدام فوورويب، قد نلغي وصولك إلى الخدمات. إذا احتجنا إلى الاتصال بك، فإننا سنرسل رسالة إلى عنوان البريد الإلكتروني الرئيسي.

                                                </p>
                                                <h3 className="positive-guidance-title"> تفعيل الحساب :</h3>
                                                <p>طبقا للمادة 2.1.2, فإن الشخص الذي يسجل للخدمة بفتح حساب هو الطرف المتعاقد (« مالك المتجر ») لأغراض شروط الاستخدام الخاصة بنا ويسمح له باستخدام أي حساب قد نقدمه إلى صاحب المتجر فيما يتعلق بالخدمة. تقع على عاتقك مسؤولية التحقق من أن اسم مالك المتجر (بما في ذلك الاسم القانوني للشركة التي تمتلك المتجر، إذا كان ذلك ممكنًا) مرئي بوضوح على موقع الويب الخاص بالمتجر.
                                                    إذا قمت بالتسجيل في الخدمات نيابة عن صاحب العمل، فإن هذا الأخير هو صاحب المتجر. في هذه الحالة، يجب عليك استخدام عنوان بريد إلكتروني صادر عن صاحب العمل، وأنت تمثل وتضمن أنه يمكنك إلزام صاحب العمل بشروط الاستخدام الخاصة بنا.
                                                    لا يمكن ربط متجر فوورويب الخاص بك إلا بمالك متجر واحد. يمكن لصاحب المتجر المصطلح امتلاك العديد من متاجر فوورويب. يعني « المتجر » المتجر عبر الإنترنت الذي تستضيفه فوورويب أو موقع ويب تابع لجهة خارجية) أو نقطة (نقاط) البيع المادية المرتبطة بالحساب.

                                                </p>
                                                <h3 className="positive-guidance-title">حقوق فوورويب:</h3>
                                                <p> تمتلك الخدمات المختلفة خيارات واسعة من الخصائص والوظائف. ليست كل الخدمات أو الميزات متاحة باستمرار لجميع التجار، ولسنا ملزمين بتقديم خدمات أو ميزات في أي ولاية قضائية. نحتفظ بالحق في تغيير الخدمات أو أي جزء من الخدمات في أي وقت لأي سبب، دون إشعار، ما لم تحظر شروط الاستخدام هذه أو القانون المعمول به مثل هذا التغيير.
                                                    فوورويب لا يجري فحص للمعدات. يمكننا، وفقًا لتقديرنا رفض أو إزالة أي مادة من أي جزء من الخدمات، بما في ذلك إذا قررنا، وفقًا لتقديرنا، أن السلع أو الخدمات التي تقدمها، أو المواد التي تم تنزيلها أو نشرها على الخدمات، تنتهك سياسة الاستخدام المقبولة أو شروط الاستخدام هذه.
                                                    أي إساءة لفظية أو مكتوبة من أي نوع (بما في ذلك التهديد بالإساءة أو الانتقام) ضد أي موظف أو عضو أو وكيل في فوورويب ستؤدي إلى الإنهاء الفوري للحساب.
                                                    نحن نحتفظ بالحق في تقديم خدماتنا لمنافسيك ولا نقدم أي وعود بالحصرية. أنت تعترف أيضًا وتوافق على أن موظفي فوورويب والمقاولين الفرعيين قد يكونون أيضًا عملاء أو تجار لفوورويب وقد يتنافسون معك، حتى لو لم يتمكنوا من استخدام معلوماتك السرية (كما هو محدد في القسم 6) لهذا الغرض.
                                                    في حالة نشوب نزاع بشأن ملكية حساب، نحتفظ بالحق في طلب مستندات داعمة لتحديد أو تأكيد ملكية الحساب. قد تتضمن أوراق الاعتماد، على سبيل المثال لا الحصر، نسخة ممسوحة ضوئيًا من رخصتك التجارية، أو بطاقة تعريف تحتوي على صورة صادرة عن الحكومة، آخر أربعة أرقام من بطاقة الائتمان الموجودة في الملف أو مستند يؤكد وضعك كموظف في كيان.
                                                    تحتفظ فوورويب بالحق في تحديد الملكية المشروعة للحساب، وفقًا لتقديرها الوحيد، وتحويل حساب إلى مالك المتجر الشرعي. إذا لم نتمكن من تحديد مالك المتجر الشرعي بشكل معقول، دون المساس بحقوقنا وسبل الانصاف الأخرى، فإننا نحتفظ بالحق في تعليق أو إلغاء تنشيط الحساب مؤقتًا حتى يتوصل الطرفان إلى اتفاق.
                                                </p>
                                                <h3 className="positive-guidance-title">مسؤولياتكم :</h3>
                                                <p> أنت تقر وتوافق على أنه يجب عليك توفير معلومات الاتصال العامة وسياسة استرداد الأموال وأوقات معالجة الطلبات في متجر فوورويب الخاص بك.
                                                    أنت تقر وتوافق على أن الخدمات لا تشكل سوقًا، وأن أي اتفاقية مبيعات يتم إبرامها من خلال الخدمات تربطك أنت وعميلك مباشرة. أنت البائع الرسمي لجميع العناصر التي تبيعها من خلال الخدمات. أنت مسؤول عن إنشاء وتشغيل متجر فوورويب الخاص بك، والمواد والسلع والخدمات التي من المحتمل أن تبيعها من خلال الخدمات، ولكن أيضًا لجميع جوانب المعاملات بينك وبين عملائك. وهذا يشمل، على سبيل المثال لا الحصر، أذون الدفع للعملاء كجزء من شرائهم، والمبالغ المستردة، والعائدات، وتقديم أي خدمات تجارية للعملاء أو التجار، والمعاملات الاحتيالية، والاتصالات القانونية الإلزامية، والامتثال للوائح، والانتهاكات الفعلية أو المزعومة للتشريعات المعمول بها (بما في ذلك قوانين حماية المستهلك في جميع الولايات القضائية التي تبيع فيها المنتجات أو الخدمات) وانتهاكات شروط الاستخدام هذه من قبلك. أنت تمثل وتضمن أن متجرك والمواد والسلع والخدمات التي تبيعها من خلال الخدمات ستكون صحيحة ودقيقة وكاملة، ولن تنتهك أي قوانين أو لوائح أو حقوق طرف ثالث. لتجنب الشك، لن يكون فوورويب هو البائع أو التاجر الرسمي، ولن يكون مسؤولاً عن متجرك أو العناصر التي تبيعها لعملائك من خلال الخدمات.
                                                    أنت مسؤول بالكامل عن السلع التي تقدمها للبيع من خلال الخدمات (بما في ذلك الوصف أو السعر أو الرسوم أو الضرائب التي تحسبها أو العيوب أو الاتصالات القانونية الإلزامية أو الامتثال أو العروض أو المحتوى الترويجي)، لا سيما فيما يتعلق بالامتثال للتشريعات أو اللوائح المعمول بها.
                                                    غير مصرح لك باستخدام خدمات فوورويب لأغراض غير قانونية أو غير مصرح بها أو لانتهاك قوانين ولايتك القضائية (بما في ذلك قوانين حقوق النشر) عند استخدام الخدمة، وهي القوانين التي تعنيك في ولاية عميلك. سوف تمتثل لجميع القوانين والقواعد واللوائح المعمول بها (بما في ذلك الحصول على تصريح أو ترخيص من المحتمل أن تحمله أو يحتمل أن يكون مطلوبًا لتشغيل متجرك، وتلبية المتطلبات المرتبطة به) عند استخدام خدمة وأداء التزاماتك بموجب شروط الاستخدام.</p>
                                                <h3 className="positive-guidance-title">ماذا يعني ذلك

                                                    :</h3>
                                                <p> أنت، وليس فوورويب، تتحمل المسؤولية الكاملة عن متجر فوورويب الخاص بك، والسلع أو الخدمات التي تبيعها وعلاقتك بعملائك.
                                                </p>
                                                <h3 className="positive-guidance-title">7. تقييد المسؤولية والتعويض

                                                    :</h3>
                                                <p> أنت تفهم وتوافق صراحة على أنه، بالقدر الذي تسمح به القوانين المعمول بها، ,فوورويب وموردوها لن يكونوا مسؤولين عن أي أضرار مباشرة، غير مباشرة, ملحقات خاصة, تبعية أو نموذجية، بما في ذلك الأضرار المتصلة بخسارة فائدة، النقل أو الاستخدام البيانات أو أي خسارة غير ملموسة أخرى ناجمة عن استخدام أو عدم القدرة على استخدام الخدمة أو شروط الاستخدام هذه (بما في ذلك الإهمال).
                                                    أنت توافق على تعويض وإعفاء فوورويب و (حسب الاقتضاء) الشركة الأم، والشركات التابعة، والشركات التابعة، والشركاء، والموظفين، والمديرين، والوكلاء، التجاريين، والموردون من أي مسؤولية تتعلق بأي مطالبة أو طلب، بما في ذلك أتعاب المحامين، التي يقدمها طرف ثالث نتيجة أو ناتجة عن (أ) انتهاكك لشروط الاستخدام هذه أو الوثائق المدمجة بالإحالة، (بما في ذلك سياسة الاستخدام المقبولة) ؛ (ب) انتهاكك لأي قانون أو حقوق لطرف ثالث ؛ أو (ج) أي جانب من جوانب المعاملة بينك و بين عميلك، بما في ذلك المبالغ المستردة أو المعاملات الاحتيالية أو الانتهاكات الفعلية أو المزعومة للتشريعات المعمول بها (بما في ذلك قوانين حماية المستهلك الحكومية)، أو انتهاكك لشروط الاستخدام.
                                                    أنت مسؤول عن أي انتهاك لشروط الاستخدام من قبل الشركات التابعة لك أو الوكلاء أو المتعاقدين، وتتحمل نفس المسؤولية كما لو كنت في المنتهك الأصلي.
                                                    أنت تستخدم الخدمات على مسؤوليتك الخاصة. وتقدمها «كما هي» و «حسب توفرها» دون أي ضمان أو شرط صريح أو ضمني أو قانوني.
                                                    لا تضمن فوورويب أن تكون الخدمات دون انقطاع, في الوقت المناسب, آمنة أو خالية من الأخطاء.
                                                    لا تضمن فوورويب أن النتائج التي يمكن الحصول عليها باستخدام الخدمات ستكون دقيقة أو موثوقة.
                                                    لا تتحمل فوورويب أيًا من مسؤولياتك أو التزاماتك الضريبية المتعلقة باستخدام خدمات فوورويب.
                                                    لا تضمن فوورويب أن جودة المنتجات أو الخدمات أو المعلومات أو المحتوى الآخر الذي اشتريته أو حصلت عليه من خلال الخدمات سترقى لتوقعاتك، أو أنه سيتم تصحيح أي أخطاء في الخدمات.</p>

                                                <h3 className="positive-guidance-title">ماذا يعني ذلك

                                                    :</h3>
                                                <p>نحن لسنا مسؤولين عن أي أضرار أو دعاوى قضائية قد تنشأ إذا انتهكت القانون أو انتهكت هذه الاتفاقية أو انتهكت حقوق أي طرف ثالث. وتقدم الخدمة «كما هي» و «حسب توفرها». نحن لا نقدم أي ضمان، ومسؤوليتنا محدودة في حالة الخطأ أو الانقطاع.</p>
                                                <h3 className="positive-guidance-title"> مامعنى حقوق محفوظة لفوورويب في الباقة الغير مدفوعة



                                                    :</h3>
                                                <p>  فريق فوورويب و طاقمنا يعني بها مصمم من طرف فوورويب وفقط ولا نقصد بها اي شيئ من الاشياء و هدفها دعائي لمنصتنا لكي يعرف المنافس بانه يستعمل متجرنا و نكسب عميل من خلاله و هذا لسبب استعمالك الباقة المجانية.</p>
                                                <h3 className="positive-guidance-title"> 8. الملكية الفكرية والمعدات



                                                    :</h3>
                                                <p>عندما تضع المحتوى على الإنترنت، تظل المالك (إذا كان هذا المحتوى ملكك) ومسؤولاً عن هذا المحتوى. ومع ذلك، قد تستخدم فوورويب وتنشر المحتوى الذي نشرته عبر الإنترنت. تظل جميع المحتويات المستوردة إلى فوورويب ملكية ومسؤولية مالكها الأصلي. ومع ذلك، نحن مرخصون حيال المواد المنشورة من خلال النظام الأساسي ويمكننا استخدام هذا الترخيص لتشغيل خدماتنا والترويج لها..</p>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutTeam;