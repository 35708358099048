import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../src/assets/fonts/remixicon.css';
import '../src/assets/css/style.css';

// Components 



import HomeFour from './component/Pages/HomeFour/HomeFour';


import About from './component/Pages/About/About';
import Contact from './component/Pages/Contact/Contact';
import Blog from './component/Pages/Blog/Blog';
import BlogDetails from './component/Pages/Blog/BlogDetails';
import Services from './component/Pages/Services/Services';
import Price from './component/Pages/Price/Price';
import Team from './component/Pages/Team/Team';
import TeamDetails from './component/Pages/TeamDetails/TeamDetails';
import Faq from './component/Pages/Faq/Faq';
import Error404 from './component/Pages/Error404/Error404';
import ScrollUpBtn from './component/Shared/ScrollUpBtn';

function App() {
  return (
    <div>
      <Helmet>
        <title>فوورويب - أفضل منصة انشاء المتاجر الالكترونية</title>
        <link rel="shortcut icon" href="favicon.png" type="image/x-icon" />
      </Helmet>

      <Routes>



        <Route path='' element={<HomeFour />}></Route>


        <Route path='/privacy' element={<About />} ></Route>
        <Route path='/84@6354' element={<Contact />}></Route>
        <Route path='/468@413' element={<Blog />}></Route>
        <Route path='/78@5678' element={<BlogDetails />}></Route>
        <Route path='/78@52787' element={<Services />}></Route>
        <Route path='/Pricing' element={<Price />}></Route>
        <Route path='/757@85' element={<Team />}></Route>
        <Route path='/t57@875' element={<TeamDetails />}></Route>
        <Route path='/78@6578' element={<Faq />}></Route>
        <Route path='*' element={<Error404 />}></Route>
      </Routes>
      <ScrollUpBtn />
      <ToastContainer />
    </div>
  );
}

export default App;
